import jwtDecode from 'jwt-decode'
import { useContext, ref, computed, readonly } from '@nuxtjs/composition-api'
import { Location, Route } from 'vue-router'
import { useNotification } from './ui/useNotification'
import { useLoading } from './ui/useLoading'
import { useEvents } from './framework/useEvents'
import { useRedirect } from './useRedirect'
import {
  User,
  LoginParams,
  RegisterParams,
  ResetPasswordParams,
} from '~/types/user'
import { ApiResult } from '~/types/common'

export const keyOfAuthToken = 'binorm_auth_token'
export const AuthRedirectKey = 'binorm_auth_redirect'

const user = ref<User | null>(null)
const loginLoading = useLoading()
const logoutLoading = useLoading()
const registerLoading = useLoading()
const forgotPasswordLoading = useLoading()
const { emit } = useEvents()
const checkForgotPasswordTokenLoading = useLoading()
const resetPasswordLoading = useLoading()
// const router = useRouter()


export enum AuthEvents {
  Login = 'auth/login',
  Logout = 'auth/logout'
}

export const useAuth = () => {
  const { app, redirect } = useContext()
  const { redirectBack } = useRedirect()
  const { show: showNotification } = useNotification()

  const authed = computed(() => {
    return !!user.value
  })

  const decodeToken = (apiToken: string) => {
    const result = jwtDecode<Record<string, any>>(apiToken)
    user.value = {
      id: parseInt(result.nameid),
      email: result.email,
      firstName: result.unique_name,
      middleName: result.middleName,
      lastName: result.lastName,
      language: result.language,
    }
    return result
  }

  const setToken = (apiToken: string) => {
    const { exp } = decodeToken(apiToken)
    app.$storage.setCookie(keyOfAuthToken, apiToken, {
      path: '/',
      expires: new Date(exp * 1000),
    })
  }

  const removeToken = () => {
    user.value = null
    app.$storage.removeCookie(keyOfAuthToken)
  }

  const checkAuth = () => {
    const token = app.$storage.getCookie(keyOfAuthToken)
    if (token) {
      setToken(token)
    } else {
      removeToken()
    }
  }

  const setPreferLanguage = (token: string) => {
    const { language } = decodeToken(token)
    if (language) {
      app.i18n.setLocale(language.toLowerCase())
    }
  }

  const login = (params: LoginParams) => {
    return loginLoading.scope<ApiResult<string>>(async () => {
      const response = await app.$api.auth.login(params)

      if (response.data) {
        setToken(response.data)
        emit(AuthEvents.Login)
        redirectBack(user.value?.language?.toLowerCase())
      } else {
        removeToken()
      }

      return response
    })
  }

  const logout = () => {
    return logoutLoading.scope(async () => {
      const response = await app.$api.auth.logout()
      if (response?.data) {
        removeToken()
        emit(AuthEvents.Logout)
        redirect(app.localePath({ name: 'index' }))
      }
    })
  }

  const register = (params: RegisterParams) => {
    return registerLoading.scope<ApiResult<string>>(async () => {
      const response = await app.$api.auth.register(params)
      if (response?.data) {
        setToken(response.data)
        redirectBack(user.value?.language?.toLowerCase())
      } else {
        removeToken()
      }

      return response
    })
  }

  const forgotPassword = (email: string) => {
    return forgotPasswordLoading.scope(async () => {
      const response = await app.$api.auth.forgotPassword(email)
      showNotification({
        template: `<span>${response?.message}</span>`,
      })
      return response
    })
  }

  const resetPassword = (params: ResetPasswordParams) => {
    return resetPasswordLoading.scope(async () => {
      const response = await app.$api.auth.resetPassword(params)
      if (response?.data) {
        showNotification({
          template: `<span>${app.i18n.t(
            'common.login.resetSuccessTip'
          )}</span>`,
        })

        redirect(
          app.localePath({
            name: 'login',
            query: { redirect: JSON.stringify({ name: 'index' }) },
          })
        )
      }
    })
  }

  const checkForgotPasswordToken = (token: string) => {
    return checkForgotPasswordTokenLoading.scope(async () => {
      const response = await app.$api.auth.checkForgotPasswordToken(token)
      if (!response?.data) {
        redirect(app.localePath({ name: 'index' }))
      } else {
        const token = app.$storage.getCookie(keyOfAuthToken)
        if (token) {
          removeToken()
        }
        return response.data
      }
    })
  }

  const ensureAuthed = (onAuthed: Function) => {
    if (authed.value) {
      onAuthed()
    } else {
      redirectLogin()
    }
  }

  const redirectLogin = (redirect?: Location, replace = false) => {
    if (!redirect) {
      const current = app.router?.currentRoute as Route
      redirect = {
        name: current.name?.split('___')[0],
        query: current.query,
        params: current.params,
      }
    }
    if (redirect) {
      app.$storage.setLocalStorage(AuthRedirectKey, redirect)
    } else {
      app.$storage.removeLocalStorage(AuthRedirectKey)
    }

    if (replace) {
      app.router?.replace(app.localePath({ name: 'login' }))
    } else {
      app.router?.push(app.localePath({ name: 'login' }))
    }
  }

  // checkAuth when page load or where use this composable
  // Note: if this logic need to fetch api or only need to execute once
  // it's not recommand handle this inside composable, should place it to global layout or in the plugin
  checkAuth()

  return {
    user: readonly(user),
    authed,
    loginLoading: readonly(loginLoading.value),
    logoutLoading: readonly(logoutLoading.value),
    registerLoading: readonly(registerLoading.value),
    forgotPasswordLoading: readonly(forgotPasswordLoading.value),
    resetPasswordLoading: readonly(resetPasswordLoading.value),
    checkForgotPasswordTokenLoading: readonly(
      checkForgotPasswordTokenLoading.value
    ),

    ensureAuthed,
    redirectLogin,

    login,
    logout,
    setToken,
    setPreferLanguage,
    register,
    forgotPassword,
    resetPassword,
    checkForgotPasswordToken,
  }
}
