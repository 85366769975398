import { symmetricDifference, propEq, append, reduce, filter } from 'ramda'
import {
  useContext,
  ref,
  readonly,
  computed,
  InjectionKey,
  provide,
  inject,
} from '@nuxtjs/composition-api'
import { useEvents } from './framework/useEvents'
import { useLoading } from './ui/useLoading'
import { useCustomer } from './useCustomer'
import { AuthEvents } from './useAuth'
import {
  Cart,
  CartLineChange,
  CartLine,
  CartLineAction,
  CartPayment,
  Totals,
  Shipment,
  CheckoutStatus,
  CheckoutStep,
  CreateOrderRequest,
  CartSession,
  DeliveryOptions,
  VoucherPayment,
} from '~/types/cart'
import { useConfig } from '~/composables/useConfig'
import { Coordinate } from '~/types/geo'
import { Customer, CustomerAddress } from '~/types/customer'
import { Voucher, VoucherKind } from '~/types/promotion'
import {numberFormat} from "~/lib/api/deserializers/product.meili";

const BATCH_INTERVAL = 1000

export enum CartEvents {
  View = 'cart/view',
  StoreChanged = 'cart/storeChanged',
  ProductsChanged = 'cart/productsChanged',
  AddProducts = 'cart/addProducts',
  RemoveProducts = 'cart/removeProducts',

  Checkout = 'cart/checkout',
  SetShipment = 'cart/setShipment',
  SetPayment = 'cart/setPayment',
  Paid = 'cart/paid',
}

export const useCartConstructor = () => {
  const { emit, on } = useEvents()
  const { app } = useContext()
  const { customer } = useCustomer()
  const { config } = useConfig()

  // Cart and loading
  const cart = ref<Cart | null>(null)
  const previewShippingCost = ref<number | null>(null)
  const loading = useLoading()
  const addingVoucher = useLoading()
  const addingCoupon = useLoading()
  const removingVoucher = useLoading()
  const removingCoupon = useLoading()
  const setAlternativeAddressLoading = useLoading()

  // Client side quantity control
  const virtualLines = ref<CartLine[] | null>(null)
  let lineChanges: CartLineChange[] = []
  let lineTimer: any = null
  const lineChangeTime = ref(0)

  // checkout cart session
  const cartSession = ref<CartSession>({
    orderReference: '',
    remark: '',
    isAccept: false,
  })

  on(
    AuthEvents.Login,
    async () => {
      await loadCart()
    },
    'cart'
  )

  const setCart = (newCart: Cart | null) => {
    if (!newCart) return
    const oldCart = cart.value
    cart.value = newCart

    // Detect store change
    if (oldCart?.store?.id !== newCart.store?.id) {
      emit(CartEvents.StoreChanged, { cart: cart.value })
    }

    // Detect products change
    if (oldCart && newCart) {
      const oldPrint = oldCart.lines
        .map((line) => `${line.productId}:${line.quantity}`)
        .join('_')
      const newPrint = newCart.lines
        .map((line) => `${line.productId}:${line.quantity}`)
        .join('_')
      if (oldPrint !== newPrint) {
        emit(CartEvents.ProductsChanged, { lines: newCart.lines })
      }
    }
  }

  //
  // Cart lines
  //
  const onLineTimerTimeout = () => {
    if (!lineChanges.length) return

    const lastTimeout =
      lineChangeTime.value + BATCH_INTERVAL - new Date().getTime()
    if (lastTimeout > 0) {
      lineTimer = setTimeout(onLineTimerTimeout, lastTimeout)
    } else {
      lineTimer = null
      flushLineChanges()
    }
  }

  const resetLineChanges = (options?: any) => {
    options = Object.assign({ resetVisualLines: false }, options)
    if (options.resetVisualLines) {
      virtualLines.value = null
    }
    lineChanges = []
    lineTimer = null
    lineChangeTime.value = 0
  }

  const isProductLine = (line: CartLine, productId: number) =>
    line.productId === productId && !line.derived

  const onChangeLines = (changes: CartLineChange[]) => {
    if (!cart.value) return

    let lines: CartLine[]
    if (virtualLines.value) {
      lines = virtualLines.value
    } else {
      lines = cart.value.lines.map((line) => ({
        productId: line.productId,
        quantity: line.quantity,
      }))
    }

    changes.forEach((change) => {
      const line = lines.find((line) => isProductLine(line, change.productId))
      switch (change.action) {
        case CartLineAction.Add:
          if (!line) {
            lines.push({
              productId: change.productId,
              quantity: change.quantity,
            })
          } else {
            line.quantity += change.quantity
          }
          break
        case CartLineAction.Update:
          if (line) {
            line.quantity = change.quantity
          }
          break
        case CartLineAction.Delete:
          if (line) {
            lines.splice(lines.indexOf(line), 1)
          }
          break
      }
    })

    virtualLines.value = lines
    lineChanges.push(...changes)
  }

  const changeLines = (changes: CartLineChange[]) => {
    onChangeLines(changes)
    lineChangeTime.value = new Date().getTime()

    if (!lineTimer) {
      lineTimer = setTimeout(onLineTimerTimeout, BATCH_INTERVAL)
    }
  }

  const changeLinesAsync = async (changes: CartLineChange[]) => {
    onChangeLines(changes)
    await flushLineChanges()
  }

  const flushLineChanges = () => {
    if (!cart.value) return

    return loading.scope(async () => {
      let newCart: Cart | null = null
      try {
        // eslint-disable-next-line no-unmodified-loop-condition
        while (lineChanges.length && !lineTimer) {
          const changes = lineChanges
          resetLineChanges()
          newCart = await app.$api.cart.changeCartLines(changes)

          // handle gtm enhanced ecommerce events
          const resolvedChanges = reduce(
            (prev: CartLineChange[], current: CartLineChange) => {
              const filterAcc = symmetricDifference(
                prev,
                filter(propEq('productId', current.productId), prev)
              )
              return append(current, filterAcc)
            },
            [],
            changes
          )

          resolvedChanges.forEach((change) => {
            const line = newCart?.lines.find((line) =>
              isProductLine(line, change.productId)
            ) as CartLine
            const oldLine = cart.value?.lines.find((line) =>
              isProductLine(line, change.productId)
            ) as CartLine

            switch (change.action) {
              case CartLineAction.Add:
                if (line) {
                  emit(CartEvents.AddProducts, {
                    lines: [line],
                  })
                }
                break
              case CartLineAction.Update:
                if (line && oldLine) {
                  if (line.quantity === oldLine.quantity) return

                  if (line.quantity > oldLine.quantity) {
                    emit(CartEvents.AddProducts, {
                      lines: [
                        {
                          ...line,
                          quantity: line.quantity - oldLine.quantity,
                        },
                      ],
                    })
                  } else {
                    emit(CartEvents.RemoveProducts, {
                      lines: [
                        {
                          ...line,
                          quantity: oldLine.quantity - line.quantity,
                        },
                      ],
                    })
                  }
                }
                break
              case CartLineAction.Delete:
                if (oldLine) {
                  emit(CartEvents.RemoveProducts, {
                    lines: [oldLine],
                  })
                }
                break
            }
          })

          if (!newCart) {
            // Discard all changes and reset
            resetLineChanges({ resetVisualLines: true })
            return
          }
        }

        setCart(newCart)
      } finally {
        if (!lineChanges.length) {
          resetLineChanges({ resetVisualLines: true })
        }
      }
    })
  }

  const overStockLines = computed(() => {
    return cartLines.value.reduce((prev, current) => {
      const maxQuantity = current.maxQuantity
      if (maxQuantity !== undefined && current.quantity > maxQuantity) {
        prev.push(current)
      }
      return prev
    }, [] as CartLine[])
  })

  const autoFixOverStock = async () => {
    if (!overStockLines.value.length) return
    const actions = overStockLines.value.reduce((prev, current) => {
      const maxQuantity = current.maxQuantity!
      prev.push({
        action: maxQuantity > 0 ? CartLineAction.Update : CartLineAction.Delete,
        productId: current.productId,
        quantity: maxQuantity,
      })
      return prev
    }, [] as CartLineChange[])
    await changeLinesAsync(actions)
  }

  const cartLines = computed(() => {
    if (!cart.value) return []
    if (!virtualLines.value) return cart.value?.lines || []

    const lines = Array<CartLine>(0)
    cart.value.lines.forEach((line) => {
      const visualLine = virtualLines.value?.find((visualLine) =>
        isProductLine(visualLine, line.productId)
      )
      if (visualLine) {
        lines.push(Object.assign({}, line, { quantity: visualLine.quantity }))
      }
    })
    return lines
  })

  const couponLines = computed(() => {
    if (!cartLines.value?.length) return []

    return cartLines.value.filter((line) =>
      cart.value?.coupons?.some((coupon) => coupon.id === line.productId)
    )
  })

  const productLines = computed(() => {
    if (!cartLines.value?.length) return []

    return cartLines.value.filter(
      (line) =>
        !couponLines.value?.some((coupon) => coupon.id === line.productId)
    )
  })

  const quantityLines = computed(() => {
    return (
      (virtualLines.value ? virtualLines.value : cart.value?.lines) ??
      ([] as CartLine[])
    )
  })

  const productQuantity = (productId: number) => {
    return (
      quantityLines.value?.find((line) => isProductLine(line, productId))
        ?.quantity ?? 0
    )
  }

  const cartLinesMap = computed(() => {
    return new Map(cartLines.value.map((line) => [line.productId, line]))
  })

  const ensureCart = () => {
    if (cart.value) return

    return loadCart(true)
  }

  const loadCart = (ensure = false) => {
    if (!process.client) return

    return loading.scope(async () => {
      if (ensure && cart.value) return

      const newCart = await app.$api.cart.getCart()
      setCart(newCart)
    }, true)
  }

  const addToCart = (
    changes: CartLineChange[] | CartLineChange | number,
    quantity: number
  ) => {
    if (typeof changes === 'number') {
      changes = [{ productId: changes as number, quantity }]
    } else {
      const change = changes as CartLineChange
      if (change.action) {
        changes = [change]
      }
    }

    changes = changes as CartLineChange[]
    if (!changes.length) return

    changes.forEach((change) => (change.action = CartLineAction.Add))

    changeLines(changes)
  }

  const changeQuantity = (change: CartLineChange) => {
    change.action = CartLineAction.Update
    changeLines([change])
  }

  const removeFromCart = (productId: number) => {
    changeLines([{ action: CartLineAction.Delete, productId, quantity: 0 }])
  }

  const emptyCart = () => {
    if (cart.value && cart.value.lines?.length) {
      emit(CartEvents.RemoveProducts, {
        cart: cart.value.lines,
      })
    }

    return loading.scope(async () => {
      const newCart = await app.$api.cart.emptyCart()
      setCart(newCart)
    })
  }

  const totalQuantity = computed(() =>
    quantityLines.value?.length
      ? quantityLines.value.map((line) => line.quantity).reduce((t, v) => t + v)
      : 0
  )

  //
  // Shipping logic
  //
  const currentAddress = computed(() => {
    if (!cart.value) return null

    return cart.value.invoiceAddress || cart.value.deliveryAddress
  })

  const setDeliveryAddress = (addressId: number, deliveryOptions?: DeliveryOptions) => {
    if (!cart.value) return

    return loading.scope(async () => {
      const newCart = await app.$api.cart.setDeliveryAddress(addressId, deliveryOptions)
      setCart(newCart)

      if (newCart) {
        emit(CartEvents.SetShipment, {
          cart: cart.value,
        })
      }
    })
  }

  const setAlternativeAddress = (
    address: CustomerAddress,
    deliveryOptions?: DeliveryOptions
  ) => {
    return loading.scope(async () => {
      const newCart = await app.$api.cart.setAlternativeAddress(address, deliveryOptions)
      setCart(newCart)
      if (newCart) {
        emit(CartEvents.SetShipment, {
          cart: cart.value,
        })
      }
    })
  }

  const setDeliveryByDeliveryAddress = async (
    addressId: number,
    deliveryOptions?: DeliveryOptions
  ) => {
    let success = false

    if (!cart.value) return

    await loading.scope(async () => {
      const newCart = await app.$api.cart.setDeliveryByDeliveryAddress(
        addressId,
        deliveryOptions
      )
      setCart(newCart)

      if (newCart) {
        emit(CartEvents.SetShipment, {
          cart: cart.value,
        })
      }

      success = !!newCart
    })
    return success
  }

  const setDeliveryByAlternativeAddress = async (
    address: CustomerAddress,
    deliveryOptions?: DeliveryOptions
  ) => {
    let success = false

    if (!cart.value) return

    await loading.scope(async () => {
      const newCart = await app.$api.cart.setDeliveryByAlternativeAddress(
        address,
        deliveryOptions
      )
      setCart(newCart)

      if (newCart) {
        emit(CartEvents.SetShipment, {
          cart: cart.value,
        })
      }
      success = !!newCart
    })

    return success
  }

  const setDeliveryByPickupLocation = async (
    address: CustomerAddress,
    deliveryOptions?: DeliveryOptions,
    method?: string,
  ) => {
    let success = false

    if (!cart.value) return

    await loading.scope(async () => {
      const newCart = await app.$api.cart.setDeliveryByPickupLocation(
        address,
        deliveryOptions,
        method
      )
      setCart(newCart)

      if (newCart) {
        emit(CartEvents.SetShipment, {
          cart: cart.value,
        })
      }
      success = !!newCart
    })

    return success
  }
  

  const setPickup = async (
    storeId: number,
    deliveryOptions?: DeliveryOptions
  ) => {
    let success = false
    if (cart.value) {
      await loading.scope(async () => {
        const newCart = await app.$api.cart.setPickup(storeId, deliveryOptions)
        setCart(newCart)

        if (newCart) {
          emit(CartEvents.SetShipment, {
            cart: cart.value,
          })
        }

        success = !!newCart
      })
    }
    return success
  }

  const setShipment = (shipment: Shipment) => {
    if (!cart.value) return

    return loading.scope(async () => {
      const newCart = await app.$api.cart.setShipment(shipment)
      if (newCart) {
        setPreviewShippingCost(null)
      }
      setCart(newCart)
    })
  }

  const setPreviewShippingCost = (shippingCost: number | null) => {
    previewShippingCost.value = shippingCost
  }

  //
  // Voucher, Payment and Order
  //
  const addVoucher = (voucherId: number) => {
    return addingVoucher.scope(async () => {
      const newCart = await app.$api.cart.addVoucher(voucherId)
      setCart(newCart)
    })
  }

  const addCoupon = (couponId: number) => {
    return addingCoupon.scope(async () => {
      const newCart = await app.$api.cart.addCoupon(couponId)
      setCart(newCart)
    })
  }

  const removeVoucher = (voucherId: number) => {
    return removingVoucher.scope(async () => {
      const newCart = await app.$api.cart.removeVoucher(voucherId)
      setCart(newCart)
    })
  }

  const removeCoupon = (couponId: number) => {
    return removingCoupon.scope(async () => {
      const newCart = await app.$api.cart.removeCoupon(couponId)
      setCart(newCart)
    })
  }

  const maxPointsToRedeem = computed(() => {
    const points = customer.value?.bonusPoints ?? 0
    const total = cart.value?.totalInclTax ?? 0
    return Math.floor(Math.min(points, total * 100))
  })

  // const setPointsToRedeem = async (points: number) => {
  //   if (!cart.value) return
  //   await loading.scope(async () => {
  //     const newCart = await app.$api.cart.setPointsToRedeem(points)
  //     setCart(newCart)
  //   })
  // }

  const setPaymentMethod = (payment: CartPayment) => {
    if (!cart.value) return

    emit(CartEvents.SetPayment, {
      payment,
      cart: cart.value,
    })

    const oldPayment = cart.value.payment
    if (
      oldPayment?.method === payment.method &&
      oldPayment?.bank === payment.bank
    )
      return

    return loading.scope(async () => {
      const newCart = await app.$api.cart.setPaymentMethod(payment)
      setCart(newCart)
    })
  }

  const createOrder = (request?: CreateOrderRequest) => {
    return loading.scope<string | null>(async () => {
      const [newCart, redirectUrl] = await app.$api.cart.createOrder(request)
      setCart(newCart)
      return redirectUrl
    })
  }

  const cartAlternativeDeliveryAddress = computed<CustomerAddress>(
    
    () => cart.value?.alternativeAddress as CustomerAddress || cart.value?.alternativeDeliveryAddress as CustomerAddress
  )
  //
  // Status
  //
  const step = computed<CheckoutStep>(() => {
    if (!cart.value) return CheckoutStep.Cart
    if (!cart.value.lines.length) return CheckoutStep.Cart

    if (cart.value.lines.some((line) => line.isValid === false))
      return CheckoutStep.Cart

    if (!cart.value.deliveryAddress && !cart.value.store)
      return CheckoutStep.Shipping
    if (!cart.value.shipping) return CheckoutStep.Shipping

    if (!cart.value.payment) return CheckoutStep.Payment

    return CheckoutStep.Order
  })

  const status = computed<CheckoutStatus>(() => {
    return {
      step: step.value,
    }
  })

  const paymentVouchers = computed<Voucher[]>(() => {
    const vouchers = cart.value?.vouchers
    if (!vouchers) return []

    return vouchers.filter((voucher) => voucher.kind === VoucherKind.Payment)
  })

  const totals = computed<Totals>(() => {
    const value = cart.value
    if (value) {
      let totalInclTax = value.totalInclTax

      // Adapt to preview shipping cost, change total
      let shippingCostInclTax = value.shippingInclTax ?? 0
      if (previewShippingCost.value !== null) {
        totalInclTax =
          totalInclTax - shippingCostInclTax + previewShippingCost.value
        shippingCostInclTax = previewShippingCost.value
      }

      let totalToPay = totalInclTax

      // Reduce voucher in order, be careful voucher paid amount might be less than voucher amount
      let voucherTotal = 0
      const voucherPayments: VoucherPayment[] = []
      paymentVouchers.value.forEach((voucher) => {
        const voucherPayment = {
          voucher,
          amount: Math.min(voucher.paymentAmount ?? 0, totalToPay),
        }
        voucherPayments.push(voucherPayment)
        totalToPay -= voucherPayment.amount
        voucherTotal += voucherPayment.amount
      })

      const redeemAmount = (value.pointsToRedeem ?? 0) / 100
      totalToPay -= redeemAmount

      return {
        deliveryCostInclTax: value.deliveryCostInclTax,
        deliveryCostExclTax: value.deliveryCostExclTax,
        palletCostExclTax: value.palletCostExclTax,
        palletCostInclTax: value.palletCostInclTax,
        
        shipping: value.shipping,
        shippingInclTax: shippingCostInclTax,
        shippingExclTax: value.shippingExclTax,

        subTotalInclTax: value.subTotalInclTax,
        subTotalExclTax: value.subTotalExclTax, // should not be used
        totalInclTax,
        totalExclTax: value.totalExclTax,
        discountInclTax: value.discountInclTax,
        discountExclTax: value.discountExclTax,
        oldTotalInclTax: value.discountInclTax
          ? totalInclTax + value.discountInclTax
          : 0,
        oldTotalExclTax: value.discountExclTax
          ? value.totalExclTax + value.discountExclTax
          : 0,

        totalTax: value.totalInclTax - value.totalExclTax,
        redeemAmount,
        vouchers: voucherPayments,
        voucherTotal,
        totalToPay,
      }
    } else {
      return {
        subTotalInclTax: 0,
        subTotalExclTax: 0,
        totalInclTax: 0,
        totalExclTax: 0,
        discountInclTax: 0,
        discountExclTax: 0,
        shippingInclTax: 0,
        shippingExclTax: 0,

        totalTax: 0,
        vouchers: [],
        totalToPay: 0,
      }
    }
  })

  const setCartCustomer = (customer: Customer) => {
    return setAlternativeAddressLoading.scope(async () => {
      const newCart = await app.$api.cart.setCartCustomer(customer)
      setCart(newCart)
    })
  }

  const getCurrentCoordinate = async () => {
    let coordinate: Coordinate | null = null
    if (cart.value?.deliveryAddress?.postalCode) {
      coordinate = await app.$api.location.getCoordinateFromPostcode(
        cart.value?.deliveryAddress?.postalCode
      )
    }
    return coordinate
  }

  const addQuantity = computed(() => {
    return config.value?.addQuantityCount
  })

  return {
    cart: readonly(cart),
    loading: loading.value,
    cartLines: readonly(cartLines),
    productLines: readonly(productLines),
    couponLines: readonly(couponLines),
    cartLinesMap,
    overStockLines,
    totals: readonly(totals),
    totalQuantity: readonly(totalQuantity),
    status,
    cartSession,
    currentAddress,

    maxPointsToRedeem,

    paymentVouchers: readonly(paymentVouchers),
    addingVoucher: addingVoucher.value,
    addingCoupon: addingCoupon.value,
    removingVoucher: removingVoucher.value,
    removingCoupon: removingCoupon.value,

    ensureCart,
    loadCart,
    setCart,
    addQuantity,
    changeLinesAsync,
    autoFixOverStock,
    addToCart,
    changeQuantity,
    removeFromCart,
    emptyCart,
    productQuantity,

    setCartCustomer,
    setDeliveryAddress,
    setAlternativeAddress,
    setAlternativeAddressLoading,
    setDeliveryByDeliveryAddress,
    setDeliveryByAlternativeAddress,
    setDeliveryByPickupLocation,
    setPickup,
    setShipment,
    setPreviewShippingCost,
    cartAlternativeDeliveryAddress,

    addVoucher,
    addCoupon,
    removeVoucher,
    removeCoupon,
    // setPointsToRedeem,
    setPaymentMethod,
    createOrder,

    getCurrentCoordinate,
  }
}

export const cartKey: InjectionKey<ReturnType<typeof useCartConstructor>> =
  Symbol('Provider:Cart')

export const provideCart = () => {
  const result = useCartConstructor()

  provide(cartKey, result)
}

export const useCart = () => {
  const result = inject(cartKey)

  if (!result) {
    throw new Error('cart provider not set')
  }

  return result
}
