import { getPageUrl, getPagePathUrl } from '~/lib/utilities'
import { ContentRequest, ContentPart } from '@/types/content'

export const SiteName = 'Binorm'

export const GlobalMenus = new Set([
  ContentPart.MainMenu,
  ContentPart.TopMenu,
  ContentPart.FooterMenu,
  ContentPart.SubFooterMenu,
])

export const RouteMetas = new Map<string, (route: any) => ContentRequest>([
  // Global fallback
  [
    '__fallback',
    () => ({
      pageMeta: false, // default: true
      objUrl: 'home',
    }),
  ],

  // Home
  [
    'index',
    () => ({
      objUrl: 'home',
      parts: [ContentPart.Blocks],
    }),
  ],

  // Cart
  [
    'cart',
    () => ({
      objUrl: 'cart',
      parts: [ContentPart.CheckoutUSPs],
    }),
  ],

  // Checkout Success
  [
    'checkout-thanks-delivery',
    () => ({
      objUrl: 'checkout-thanks',
    }),
  ],
  [
    'checkout-thanks-pickup',
    () => ({
      objUrl: 'checkout-thanks-pickup',
    }),
  ],

  // Product category
  [
    'product-lister-all',
    (route) => ({
      pageMeta: false,
      type: 'ProductCategory', // default: 'Content'
      pathUrl: getPagePathUrl(route.params.pathMatch),
      objUrl: getPageUrl(route.params.pathMatch),
      parts: [ContentPart.Blocks],
    }),
  ],

  // Search result
  [
    'search-slug',
    () => ({
      type: 'ProductCategory',
      objUrl: 'zoeken',
    }),
  ],

  // Product Detail
  [
    'product-detail-slug',
    (route) => {
      const lastIndex = route.params.slug.lastIndexOf('-')
      route.params.slug.substring(lastIndex + 1)
      return {
        // selfLoad: true,
        pageMeta: false,
        type: 'Product',
        objUrl: route.params.slug.substring(lastIndex + 1),
        parts: [ContentPart.ContentMap]
      }
    },
  ],

  // Brand
  [
    'brand-lister-all',
    (route) => ({
      pageMeta: false,
      type: 'Brand',
      pathUrl: getPagePathUrl(route.params.pathMatch),
      objUrl: getPageUrl(route.params.pathMatch),
      parts: [ContentPart.Blocks],
    }),
  ],

  // Store lister
  [
    'store-lister',
    () => ({
      objUrl: 'vestigingen',
      parts: [ContentPart.VestigingBanners],
    }),
  ],

  // Store detail
  [
    'store-detail-slug',
    (route) => ({
      objUrl: route.params.slug,
      type: 'Company',
      parts: [ContentPart.CompanyServices],
    }),
  ],

  // Blog
  [
    'blog-tag-all',
    (route) => ({
      type: 'Tag',
      objUrl: route.params.pathMatch,
      parts: [ContentPart.NewsItems],
    }),
  ],

  // Blog detail
  [
    'blog-detail-slug',
    (route) => ({
      type: 'NewsItem',
      objUrl: route.params.slug,
    }),
  ],

  // Blog category
  [
    'blog-category-all',
    (route) => ({
      type: 'NewsCategory',
      objUrl: route.params.pathMatch,
    }),
  ],

  // FAQ
  [
    'faq',
    () => ({
      objUrl: 'faq',
    }),
  ],

  // All
  [
    'all',
    (route) => ({
      objUrl: route.params.pathMatch,
      parts: [ContentPart.WildPageMenu, ContentPart.Blocks],
    }),
  ],
])

export const PageMetaMap = new Map([
  ['name', ['apple-mobile-web-app-title', 'og:site_name', 'twitter:site']],
  ['title', ['twitter:title', 'og:title']],
  ['type', ['og:type']],
  ['description', ['description', 'twitter:description', 'og:description']],
  ['img', ['twitter:image', 'twitter:card', 'og:image']],
  ['keywords', ['keywords']],
  ['link', ['twitter:url', 'og:url']],
  ['locale', ['og:locale']],
  ['alternateLocales', ['og:locale:alternate']],
  ['domain', ['twitter:domain', 'og:host']],
  ['robots', ['robots']],
])
